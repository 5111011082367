<template>
  <div class="row">
    <div class="col-md-12">
      <div class="personalWrapper">
        <div class="d-flex">
          <div class="d-inline-flex flex-grow-1 fs-1 text-info">Personal Detail</div>
          <div class="d-inline-flex align-self-end">
            <a href="/partner/update/personal" class="link-secondary fs-3">
              <i class="fa fa-edit"></i> Edit Personal Detail
            </a>
          </div>
        </div>

        <div class="row">
          <div class="mb-3 col-lg-3 col-md-6">
            <label for="pdFirstName" class="label-require">First name</label>
            <div class="form-text">{{ $data.personal.first_name }}</div>
          </div>
          <div class="mb-3 col-lg-3 col-md-6">
            <label for="pdLastName" class="label-require">Last name</label>

            <div class="form-text">{{ $data.personal.last_name }}</div>
          </div>
          <div class="mb-3 col-lg-3 col-md-6">
            <label for="pdPinCode" class="label-require">Pin Code</label>

            <div class="form-text">{{ $data.personal.pin_code }}</div>
          </div>

          <div class="mb-3 col-lg-3 col-md-6">
            <label for="pdResidentialAddress" class="label-require">Residential Address</label>
            <div class="form-text">{{ $data.personal.residential_address }}</div>
          </div>
        </div>
        <div class="row"></div>
        <div class="row">
          <div class="mb-3 col-lg-3 col-md-6">
            <label for="pdResidentialAddress">Address 2</label>
            <div class="form-text">{{ $data.personal.address2 }}</div>
          </div>
          <div class="mb-3 col-lg-3 col-md-6">
            <label for="pdCountry" class="label-require">Country</label>
            <div class="form-text">
              <span v-for="(item, index) in $data.countryList" :key="index">
                <span v-if="item.no == $data.personal.country_id">{{ item.name }}</span>
              </span>
            </div>
          </div>
          <div class="mb-3 col-lg-3 col-md-6">
            <label class="label-require">State</label>
            <div class="form-text" id="pdStateSelect">
              <span v-for="(item, index) in $data.stateList" :key="index">
                <span v-if="item.no == $data.personal.state_id">{{ item.name }}</span>
              </span>
            </div>
            <div
              class="form-text"
              id="pdStateInput"
              v-if="$data.personal.state_other != null && $data.personal.state_other != ''"
            >
              {{ $data.personal.state_other }}
            </div>
          </div>
          <div class="mb-3 col-lg-3 col-md-6">
            <label for="pdCityInput" class="label-require">City</label>
            <div class="form-text" id="pdCitySelect">
              <span v-for="(item, index) in $data.cityList" :key="index">
                <span v-if="item.no == $data.personal.city_id">{{ item.name }}</span>
              </span>
            </div>
            <div
              class="form-text"
              id="pdCityInput"
              v-if="$data.personal.city_other != null && $data.personal.city_other != ''"
            >
              {{ $data.personal.city_other }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import LoadingIcons from '../../common/LoadingIcons.vue';
export default {
  name: 'PartnerSignup',
  components: {
    // LoadingIcons,
  },
  data: function () {
    return {
      personal: {},
      agencyCountry: '',
      ajaxLoad: {},
      errors: {},
    };
  },
  methods: {
    async pdCountrySelectChange() {
      var pdCountry = document.getElementById('pdCountry').value; //India : 102
      console.log('pdCountry', pdCountry);
      if (pdCountry == 102) {
        document.getElementById('pdStateInput').classList.add('d-none');
        document.getElementById('pdStateSelect').classList.remove('d-none');
        document.getElementById('pdCityInput').classList.add('d-none');
        document.getElementById('pdCitySelect').classList.remove('d-none');
        let self = this;
        // console.log("state $store ", this.$store.state)
        let apiUrl = process.env.VUE_APP_API_URL;

        await fetch(apiUrl + 'common/get-state', {
          method: 'POST',
          headers: {
            // 'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
          },
          body: 'country=' + pdCountry,
        })
          .then((response) => response.json())
          .then((resData) => {
            // console.log('Success:', resData);
            // let messages = resData.messages
            if (resData.status == 'error') {
              alert('error while geting data ' + resData.message);
              return;
            }
            self.$data.stateList = resData.stateList;
          })
          .catch(function (err) {
            console.log('error', err);
          });
        return;
      }
      document.getElementById('pdStateInput').classList.remove('d-none');
      document.getElementById('pdStateSelect').classList.add('d-none');
      document.getElementById('pdCityInput').classList.remove('d-none');
      document.getElementById('pdCitySelect').classList.add('d-none');
    },
    async pdStateSelectChange() {
      var pdCountry = document.getElementById('pdCountry').value; //India : 102

      if (pdCountry == 102) {
        document.getElementById('pdStateInput').classList.add('d-none');
        document.getElementById('pdStateSelect').classList.remove('d-none');
        document.getElementById('pdCityInput').classList.add('d-none');
        document.getElementById('pdCitySelect').classList.remove('d-none');
        var pdState = document.getElementById('pdStateSelect').value;
        let self = this;
        // console.log("state $store ", this.$store.state)
        let apiUrl = process.env.VUE_APP_API_URL;

        await fetch(apiUrl + 'common/get-city', {
          method: 'POST',
          headers: {
            // 'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
          },
          body: 'country=' + pdCountry + '&state=' + pdState,
        })
          .then((response) => response.json())
          .then((resData) => {
            // console.log('Success:', resData);
            // let messages = resData.messages
            if (resData.status == 'error') {
              alert('error while geting data ' + resData.message);
              return;
            }
            self.$data.cityList = resData.cityList;
          })
          .catch(function (err) {
            console.log('error', err);
          });
        return;
      }
    },
    async save() {
      console.log('nextPage from personal');
      //eslint-disable-next-line
      var data = {};
      this.$data.errors = {};
      var pdId = document.getElementById('pdId').value;
      var pdFirstName = document.getElementById('pdFirstName').value;
      var pdLastName = document.getElementById('pdLastName').value;
      var pdPinCode = document.getElementById('pdPinCode').value;
      var pdResidentialAddress = document.getElementById('pdResidentialAddress').value;
      var pdCountry = document.getElementById('pdCountry').value; //India : 102
      var pdCountryText =
        document.getElementById('pdCountry').options[
          document.getElementById('pdCountry').selectedIndex
        ].text;
      var pdState = document.getElementById('pdStateInput').value; //India : 02
      var pdCity = document.getElementById('pdCityInput').value; //India : 02
      // var pdState, pdCity;
      if (pdFirstName == '') {
        this.$data.errors.pdFirstName = ['This field is required'];
      }
      if (pdLastName == '') {
        this.$data.errors.pdLastName = ['This field is required'];
      }
      if (pdPinCode == '') {
        this.$data.errors.pdPinCode = ['This field is required'];
      }
      if (pdResidentialAddress == '') {
        this.$data.errors.pdResidentialAddress = ['This field is required'];
      }
      if (pdCountry == '') {
        this.$data.errors.pdCountry = ['This field is required'];
      }
      var pdStateId = 0,
        pdStateText = pdState,
        pdCityId = 0,
        pdCityText = pdCity;
      if (pdCountry == 102) {
        pdState = '';
        pdCity = '';
        //india
        pdStateId = document.getElementById('pdStateSelect').value; //India : 02
        pdCityId = document.getElementById('pdCitySelect').value; //India : 02
        pdStateText =
          document.getElementById('pdStateSelect').options[
            document.getElementById('pdStateSelect').selectedIndex
          ].text;
        pdCityText =
          document.getElementById('pdCitySelect').options[
            document.getElementById('pdCitySelect').selectedIndex
          ].text;
        if (pdStateId == '') {
          this.$data.errors.pdState = ['This field is required'];
        }
        if (pdCityId == '') {
          this.$data.errors.pdCity = ['This field is required'];
        }
      } else {
        if (pdState == '') {
          this.$data.errors.pdState = ['This field is required'];
        }
        if (pdCity == '') {
          this.$data.errors.pdCity = ['This field is required'];
        }
      }

      let self = this;
      //---check email and mobile number is existed or not----------
      this.$data.ajaxLoad.normal = true;
      // console.log("state $store ", this.$store.state)
      let apiUrl = process.env.VUE_APP_API_URL;
      let token = this.$store.state.token;
      let headersObject = new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      });
      let objCheckExisted = {};
      objCheckExisted.id = pdId;
      objCheckExisted.pdFirstName = pdFirstName;
      objCheckExisted.pdLastName = pdLastName;
      objCheckExisted.pdPinCode = pdPinCode;
      objCheckExisted.pdResidentialAddress = pdResidentialAddress;
      objCheckExisted.pdAddress2 = document.getElementById('pdAddress2').value;
      objCheckExisted.pdCountry = pdCountry;
      objCheckExisted.pdState = pdState;
      objCheckExisted.pdCity = pdCity;
      objCheckExisted.pdStateId = pdStateId;
      objCheckExisted.pdCityId = pdCityId;
      objCheckExisted.pdStateText = pdStateText;
      objCheckExisted.pdCityText = pdCityText;
      objCheckExisted.pdCountryText = pdCountryText;
      // self.$router.push('/partner/signup-personal-check-email');
      console.log('personal data ', data);
      const formData = Object.keys(objCheckExisted)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(objCheckExisted[key]))
        .join('&');
      await fetch(apiUrl + 'partner/update-personal', {
        method: 'POST',
        headers: headersObject,
        body: formData,
      })
        .then((response) => response.json())
        .then((resData) => {
          // console.log('Success:', resData);

          if (resData.status == 'error') {
            self.$data.ajaxLoad.normal = false;
            return;
          }
          self.$data.personal.first_name = pdFirstName;
          self.$data.personal.last_name = pdLastName;
          self.$data.personal.pin_code = pdPinCode;
          self.$data.personal.residential_address = objCheckExisted.pdResidentialAddress;
          self.$data.personal.address2 = objCheckExisted.pdAddress2;
          self.$data.personal.country_id = objCheckExisted.pdCountry;
          self.$data.personal.state_id = objCheckExisted.pdStateId;
          self.$data.personal.city_id = objCheckExisted.pdCityId;
          self.$data.personal.state_other = objCheckExisted.pdStateText;
          self.$data.personal.city_other = objCheckExisted.pdCityText;
          self.$data.ajaxLoad.normal = false;
          // self.$router.push('/partner/signup-personal-check-email'); //partner/signup-personal-check-email
        })
        .catch(function (err) {
          console.log('error', err);
        });
      self.$data.ajaxLoad.normal = false;
      //---end check email and mobile number is existed or not---------
      if (
        Object.keys(this.$data.errors).length !== 0 ||
        Object.getPrototypeOf(this.$data.errors) !== Object.prototype
      ) {
        return;
      }

      // this.$emit('fromChildPersonalNextPage', data);
    },
  },
  async created() {
    document.title = 'Trepr - Partner signup';
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        'content',
        'Trepr caters to both travelers and seekers who want travel companions/send packing service for seekers and to travelers who want to earn money during their travel'
      );
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        'content',
        'Trepr, trepr partner signup, trepr partner register, Travel companion, Package services, Flight companionship, Flight companion for elders, Package shipment, Shopping conceirge'
      );
    let token = this.$store.state.token;
    let self = this;
    // console.log("state $store ", this.$store.state)
    let apiUrl = process.env.VUE_APP_API_URL;
    let headerAuthentication = new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    var ajaxCountryList = fetch(apiUrl + 'common/custom-country-list', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
      },
    }).then((response) => response.json());
    var ajaxDetail = fetch(apiUrl + 'partner/detail', {
      method: 'POST',
      headers: headerAuthentication,
      body: 'type=personal',
    }).then((response) => response.json());
    await Promise.all([ajaxCountryList, ajaxDetail])
      .then((resData) => {
        // console.log(resData);
        if (resData[0].status == 'error') {
          alert('error while geting data ' + resData[0].message);
          return;
        }
        self.$data.countryList = resData[0].countryList;
        if (resData[1].status == 'error') {
          alert('error while geting data ' + resData[1].message);
          return;
        }
        self.$data.personal = resData[1].personal;
      })
      .catch((err) => {
        console.error('Error:', err);
      });
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
@import '../../../assets/scss/new/partner/partner_signup.scss';
</style>
