<template>
  <div class="row">
    <div class="col-md-12">
      <div class="childAgencyWrapper">
        <div class="d-flex">
          <div class="d-inline-flex flex-grow-1 fs-1 text-info">Agency Details</div>
          <div class="d-inline-flex align-self-end">
            <a href="/partner/update/agent" class="link-secondary fs-3">
              <i class="fa fa-edit"></i> Edit Agency Details
            </a>
          </div>
        </div>
        <div class="row g-3">
          <div class="col-lg-3 col-md-6">
            <label for="adFirstName" class="">Agency Name</label>
            <div class="form-text">{{ $data.agent.agency_name }}</div>
          </div>
          <div class="col-lg-3 col-md-6">
            <label for="adPAN" class="">PAN</label>
            <div class="form-text">{{ $data.agent.pan }}</div>
          </div>
          <div class="mb-3 col-lg-3 col-md-6">
            <label for="adPANCardCopy" class="">PAN Card Copy</label>
            <img :src="$data.agent.pan_card_copy" class="img-thumbnail" />
          </div>
          <div class="col-lg-3 col-md-6">
            <label for="adMobileNumber" class="">Mobile Number</label>
            <div class="form-text">{{ $data.agent.mobile }}</div>
          </div>
        </div>
        <div class="row g-3 mt-2">
          <div class="col-lg-3 col-md-6">
            <label for="adMobileNumber" class="">Fax</label>
            <div class="form-text">{{ $data.agent.fax }}</div>
          </div>
          <div class="col-lg-3 col-md-6">
            <label for="adPinCode" class="">Pin Code</label>
            <div class="form-text">{{ $data.agent.pin_code }}</div>
          </div>
          <div class="col-lg-3 col-md-6">
            <label for="adBusinessType" class="">Business Type</label>
            <div id="adBusinessType" aria-label="adBusinessType">
              <span v-if="$data.agent.business_type == 0"></span>
              <span value="1" v-if="$data.agent.business_type == 1"> Sole Proprietor </span>
              <span value="2" v-if="$data.agent.business_type == 2">Partnership</span>
              <span value="3" v-if="$data.agent.business_type == 3">Joint Venture</span>
              <span value="4" v-if="$data.agent.business_type == 4">PVT LTD. CO.</span>
              <span value="5" v-if="$data.agent.business_type == 5">HUF</span>
              <span value="6" v-if="$data.agent.business_type == 6">Limited</span>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <label for="adAgencyAddress" class="">Agency Address</label>
            <div class="form-text">{{ $data.agent.agency_address }}</div>
          </div>
        </div>
        <div class="row g-3 mt-2">
          <div class="col-lg-3 col-md-6">
            <label for="adAddress2">Address 2</label>
            <div class="form-text">{{ $data.agent.address2 }}</div>
          </div>
          <div class="col-lg-3 col-md-6">
            <label for="adCountry" class="">Country</label>
            <div class="form-text">{{ $data.currentCountry }}</div>
          </div>
          <div class="col-lg-3 col-md-6">
            <label class="">State</label>
            <div class="form-text">
              {{ $data.agent.state }}
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <label for="adCityInput" class="">City</label>
          <div class="form-text">
            {{ $data.agent.city }}
          </div>
        </div>
      </div>
      <div class="row g-3 mt-2">
        <div class="col-lg-3 col-md-6">
          <label for="adSecuritizationMode" class="">Securitization Mode</label>
          <div class="form-text" id="adSecuritizationMode" aria-label="adSecuritizationMode">
            <span value="3" v-if="$data.agent.securitization_mode == 3">Unsecure</span>
            <span value="1" v-if="$data.agent.securitization_mode == 1"> Bank Guarantee </span>
            <span value="2" v-if="$data.agent.securitization_mode == 2"> Post Dated Cheque </span>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <label for="adYearsInBusiness" class="">Years in Business</label>
          <div class="form-text">{{ $data.agent.years_in_business }} Years</div>
        </div>
        <div class="col-lg-3 col-md-6">
          <label for="adMonthlyBookingVolume" class=""> Monthly Booking volume? </label>
          <div class="form-text">
            {{ $data.agent.monthly_booking_volume }}
          </div>
        </div>
        <div class="mb-3 col-lg-3 col-md-6">
          <label for="" class="">Office Space</label> <br />
          <div class="form-text">
            {{ $data.agent.office_space == 0 ? 'Rented' : 'Owned' }}
          </div>
        </div>
      </div>
      <div class="row g-3 mt-2">
        <div class="mb-3 col-lg-3 col-md-6">
          <label for="" class="">IATA Registered</label> <br />
          <div class="form-text">
            {{ $data.agent.iata_registered == 0 ? 'No' : 'Yes' }}
          </div>
        </div>
        <div class="col-lg-3 col-md-6 d-none" id="adIATARegistered1">
          <label for="adIATACode" class="">IATA Code</label>
          <div class="form-text">
            {{ $data.agent.iata_code }}
          </div>
        </div>
        <div class="col-lg-3 col-md-6 d-none" id="adIATARegistered2">
          <label for="adIATATDSExcemption" class="">TDS Excemption</label>
          <div class="form-text">
            {{ $data.agent.iata_tds_exemption }}
          </div>
        </div>
        <div class="col-lg-3 col-md-6 d-none" id="adIATARegistered3">
          <label for="adIATATDSPercentForExcemption" class=""> TDS percent for Excemption </label>
          <div class="form-text">
            {{ $data.agent.iata_tds_percent }}
          </div>
        </div>
      </div>
      <div class="row g-3 mt-2">
        <div class="col-lg-4">
          <div class="">
            <label for="adReferences">References: (optional)</label>
            <div class="form-text">
              {{ $data.agent.references }}
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="">
            <label for="adConsolidators">Consolidators: (optional)</label>
            <div class="form-text">
              {{ $data.agent.consolidators }}
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="">
            <label for="adRemarks">Remarks: (optional)</label>
            <div class="form-text">
              {{ $data.agent.remarks }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import LoadingIcons from '../../common/LoadingIcons.vue';
export default {
  name: 'PartnerSignup',
  components: {
    // LoadingIcons,
  },
  data: function () {
    return {
      errors: {},
      ajaxLoad: {
        next: false,
      },
      countryList: [],
      agent: {},
      stateList: [],
      cityList: [],
      currentCountry: '',
      currentState: '',
      currentCity: '',
    };
  },
  async created() {
    document.title = 'Trepr - Partner signup';
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        'content',
        'Trepr caters to both travelers and seekers who want travel companions/send packing service for seekers and to travelers who want to earn money during their travel'
      );
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        'content',
        'Trepr, trepr partner signup, trepr partner register, Travel companion, Package services, Flight companionship, Flight companion for elders, Package shipment, Shopping conceirge'
      );
    let token = this.$store.state.token;
    let self = this;
    // console.log("state $store ", this.$store.state)
    let apiUrl = process.env.VUE_APP_API_URL;
    let headerAuthentication = new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    var ajaxCountryList = fetch(apiUrl + 'common/custom-country-list', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
      },
    }).then((response) => response.json());
    var ajaxDetail = fetch(apiUrl + 'partner/detail', {
      method: 'POST',
      headers: headerAuthentication,
      body: 'type=agent',
    }).then((response) => response.json());
    await Promise.all([ajaxCountryList, ajaxDetail])
      .then((resData) => {
        // console.log(resData);
        if (resData[0].status == 'error') {
          alert('error while geting data ' + resData[0].message);
          return;
        }

        if (resData[1].status == 'error') {
          alert('error while geting data ' + resData[1].message);
          return;
        }
        self.$data.countryList = resData[0].countryList;

        self.$data.agent = resData[1].agent;
        self.$data.countryList.forEach((item) => {
          if (item.no == self.$data.agent.country_id) {
            self.$data.currentCountry = item.name;
          }
        });
      })
      .catch((err) => {
        console.error('Error:', err);
      });
  },
  mounted() {
    if (document.getElementById('adPANCardCopy') != null) {
      document.getElementById('adPANCardCopy').onchange = function () {
        if (this.files && this.files[0]) {
          var files = this.files[0];
          let limitedUploadSize = parseInt(process.env.VUE_APP_LIMITED_UPLOAD_SIZE);
          let mbSize = limitedUploadSize / 1000000;
          if (files.size > limitedUploadSize) {
            this.$data.errors.common = [
              'We only allow maximum ' +
                mbSize.toString() +
                'per file. Please choose a smaller file',
            ];
            return false;
          }
          var reader = new FileReader();
          reader.onload = function (e) {
            document.getElementById('adPANCardCopyHidden').value = e.target.result;
          };
          reader.readAsDataURL(this.files[0]);
        }
      };
    }
  },
};
</script>

<style scoped lang="scss">
@import '../../../assets/scss/new/partner/partner_signup.scss';
@import '../../../../node_modules/bs-stepper/dist/css/bs-stepper.min.css';
#bs-stepper-header .step {
  .btn-muted {
    background-color: #eee !important;
  }
  .step-trigger {
    padding: 10px;
  }
}
#bs-stepper-header .active .step-trigger {
  background-color: rgb(41, 115, 115) !important;
  span {
    color: #fff !important;
  }
}
</style>
