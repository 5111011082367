<template>
  <div v-if="$data.gst != false">
    <div class="row">
      <div class="col-md-12">
        <div class="agencyGstDetailsWrapper" id="agencyGstDetailsWrapper">
          <div class="d-flex">
            <div class="d-inline-flex flex-grow-1 fs-1 text-info">Agency GST Details</div>
            <div class="d-inline-flex align-self-end">
              <a href="/partner/update/personal" class="link-secondary fs-3">
                <i class="fa fa-edit"></i> Edit Agency GST Details
              </a>
            </div>
          </div>
          <div class="row">
            <div class="mb-3 col-lg-3 col-md-6">
              <label for="gstAgencyName">Agency Name</label>
              <div class="form-text">
                {{ $data.gst.agency_name }}
              </div>
            </div>
            <div class="mb-3 col-lg-3 col-md-6">
              <label for="gstAgencyClassification" class="label-require">
                Agency Classification
              </label>
              <div
                class="form-text"
                id="gstAgencyClassification"
                aria-label="gstAgencyClassification"
              >
                <span v-if="$data.gst.agency_classification == 5"> Unregistered </span>
                <span v-if="$data.gst.agency_classification == 8"> Registered </span>
                <span v-if="$data.gst.agency_classification == 10"> AppliedFor </span>
              </div>
            </div>
            <div class="mb-3 col-lg-3 col-md-6">
              <label for="gstAgencyGSTIN">Agency GSTIN</label>
              <div class="form-text">
                {{ $data.gst.agency_gstin }}
              </div>
            </div>
            <div class="mb-3 col-lg-3 col-md-6">
              <div class="form-text" id="gstSupplyType" aria-label="gstState">
                <span value="1" v-if="$data.gst.supply_type == 1">TAX</span>
                <span value="2" v-if="$data.gst.supply_type == 2">SEZWOP</span>
              </div>
              <label for="gstSupplyType"> Supply Type </label>
            </div>
          </div>
          <div class="row">
            <div class="mb-3 col-lg-3 col-md-6">
              <label for="gstContactPerson">Contact Person</label>
              <div class="form-text">
                {{ $data.gst.contact_person }}
              </div>
            </div>
            <div class="mb-3 col-lg-3 col-md-6">
              <label for="gstMobileNumber"> Mobile Number </label>
              <div class="form-text">
                {{ $data.gst.mobile }}
              </div>
            </div>
            <div class="mb-3 col-lg-3 col-md-6">
              <label for="gstPhoneNumber"> Phone Number </label>
              <div class="form-text">
                {{ $data.gst.phone }}
              </div>
            </div>
            <div class="mb-3 col-lg-3 col-md-6">
              <label for="gstPINCode" class="label-require">PIN Code</label>
              <div class="form-text">
                {{ $data.gst.pin_code }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mb-3 col-lg-3 col-md-6">
              <label for="gstEmailId"> Email Id </label>
              <div class="form-text">
                {{ $data.gst.email }}
              </div>
            </div>

            <div class="mb-3 col-lg-3 col-md-6">
              <label for="gstCorrespondenceMailID"> Correspondence Mail ID </label>
              <div class="form-text">
                {{ $data.gst.correspondence_email }}
              </div>
            </div>
            <div class="mb-3 col-lg-3 col-md-6">
              <label for="gstRegistrationStatus"> GST Registration Status </label>
              <div class="form-text">
                {{ $data.gst.gst_registration_status }}
              </div>
            </div>
            <div class="mb-3 col-lg-3 col-md-6">
              <label for="gstHSNSACCode">HSN/SAC Code</label>
              <div class="form-text">
                {{ $data.gst.hsn_sac_code }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mb-3 col-lg-3 col-md-6">
              <label for="gstState" class="label-require"> State </label>
              <div class="form-text" id="gstState" aria-label="gstState">
                <span value="1" v-if="$data.gst.state_id == 1"> Andaman and Nicobar Islands </span>
                <span value="2" v-if="$data.gst.state_id == 2">Andhra Prgstesh</span>
                <span value="96" v-if="$data.gst.state_id == 96"> Andhra Prgstesh (New) </span>
                <span value="3" v-if="$data.gst.state_id == 3">Arunachal Prgstesh</span>
                <span value="4" v-if="$data.gst.state_id == 4">Assam</span>
                <span value="5" v-if="$data.gst.state_id == 5">Bihar</span>
                <span value="6" v-if="$data.gst.state_id == 6">Chandigarh</span>
                <span value="7" v-if="$data.gst.state_id == 7">Chhattisgarh</span>
                <span value="8" v-if="$data.gst.state_id == 8"> Dgstra and Nagar Haveli </span>
                <span value="9" v-if="$data.gst.state_id == 9">Daman and Diu</span>
                <span value="10" v-if="$data.gst.state_id == 10">Delhi</span>
                <span value="11" v-if="$data.gst.state_id == 11">Goa</span>
                <span value="12" v-if="$data.gst.state_id == 12">Gujarat</span>
                <span value="13" v-if="$data.gst.state_id == 13">Haryana</span>
                <span value="14" v-if="$data.gst.state_id == 14">Himachal Prgstesh</span>
                <span value="15" v-if="$data.gst.state_id == 15">Jammu and Kashmir</span>
                <span value="16" v-if="$data.gst.state_id == 16">Jharkhand</span>
                <span value="17" v-if="$data.gst.state_id == 17">Karnataka</span>
                <span value="18" v-if="$data.gst.state_id == 18">Kerala</span>
                <span value="97" v-if="$data.gst.state_id == 97">Lgstakh</span>
                <span value="19" v-if="$data.gst.state_id == 19">Lakshgstweep</span>
                <span value="20" v-if="$data.gst.state_id == 20">Mgsthya Prgstesh</span>
                <span value="21" v-if="$data.gst.state_id == 21">Maharashtra</span>
                <span value="22" v-if="$data.gst.state_id == 22">Manipur</span>
                <span value="23" v-if="$data.gst.state_id == 23">Meghalaya</span>
                <span value="24" v-if="$data.gst.state_id == 24">Mizoram</span>
                <span value="25" v-if="$data.gst.state_id == 25">Nagaland</span>
                <span value="26" v-if="$data.gst.state_id == 26">Orissa</span>
                <span value="27" v-if="$data.gst.state_id == 27">Pondicherry</span>
                <span value="28" v-if="$data.gst.state_id == 28">Punjab</span>
                <span value="29" v-if="$data.gst.state_id == 29">Rajasthan</span>
                <span value="30" v-if="$data.gst.state_id == 30">Sikkim</span>
                <span value="31" v-if="$data.gst.state_id == 31">Tamil Ngstu</span>
                <span value="95" v-if="$data.gst.state_id == 95">Telangana</span>
                <span value="32" v-if="$data.gst.state_id == 32">Tripura</span>
                <span value="34" v-if="$data.gst.state_id == 34">Uttar Prgstesh</span>
                <span value="33" v-if="$data.gst.state_id == 33">Uttarakhand</span>
                <span value="35" v-if="$data.gst.state_id == 35">West Bengal</span>
              </div>
            </div>
            <div class="mb-3 col-lg-3 col-md-6">
              <label for="gstStateCode" class="label-require"> State Code</label>
              <div class="form-text" id="gstStateCode" aria-label="gstState">
                <span value="1" v-if="$data.gst.state_code == 1">35</span>
                <span value="2" v-if="$data.gst.state_code == 2">28</span>
                <span value="96" v-if="$data.gst.state_code == 96">37</span>
                <span value="3" v-if="$data.gst.state_code == 3">12</span>
                <span value="4" v-if="$data.gst.state_code == 4">18</span>
                <span value="5" v-if="$data.gst.state_code == 5">10</span>
                <span value="6" v-if="$data.gst.state_code == 6">4</span>
                <span value="7" v-if="$data.gst.state_code == 7">22</span>
                <span value="8" v-if="$data.gst.state_code == 8">26</span>
                <span value="9" v-if="$data.gst.state_code == 9">25</span>
                <span value="10" v-if="$data.gst.state_code == 10">7</span>
                <span value="11" v-if="$data.gst.state_code == 11">30</span>
                <span value="12" v-if="$data.gst.state_code == 12">24</span>
                <span value="13" v-if="$data.gst.state_code == 13">6</span>
                <span value="14" v-if="$data.gst.state_code == 14">2</span>
                <span value="15" v-if="$data.gst.state_code == 15">1</span>
                <span value="16" v-if="$data.gst.state_code == 16">20</span>
                <span value="17" v-if="$data.gst.state_code == 17">29</span>
                <span value="18" v-if="$data.gst.state_code == 18">32</span>
                <span value="97" v-if="$data.gst.state_code == 97">38</span>
                <span value="19" v-if="$data.gst.state_code == 19">31</span>
                <span value="20" v-if="$data.gst.state_code == 20">23</span>
                <span value="21" v-if="$data.gst.state_code == 21">27</span>
                <span value="22" v-if="$data.gst.state_code == 22">14</span>
                <span value="23" v-if="$data.gst.state_code == 23">17</span>
                <span value="24" v-if="$data.gst.state_code == 24">15</span>
                <span value="25" v-if="$data.gst.state_code == 25">13</span>
                <span value="26" v-if="$data.gst.state_code == 26">21</span>
                <span value="27" v-if="$data.gst.state_code == 27">34</span>
                <span value="28" v-if="$data.gst.state_code == 28">3</span>
                <span value="29" v-if="$data.gst.state_code == 29">8</span>
                <span value="30" v-if="$data.gst.state_code == 30">11</span>
                <span value="31" v-if="$data.gst.state_code == 31">33</span>
                <span value="95" v-if="$data.gst.state_code == 95">36</span>
                <span value="32" v-if="$data.gst.state_code == 32">16</span>
                <span value="34" v-if="$data.gst.state_code == 34">9</span>
                <span value="33" v-if="$data.gst.state_code == 33">5</span>
                <span value="35" v-if="$data.gst.state_code == 35">19</span>
              </div>
            </div>
            <div class="mb-3 col-lg-3 col-md-6">
              <label for="gstAddressLine1" class="label-require">Address Line 1</label>
              <div class="form-text">
                {{ $data.gst.address1 }}
              </div>
            </div>
            <div class="mb-3 col-lg-3 col-md-6">
              <label for="gstAddressLine2">Address Line 2(optional)</label>
              <div class="form-text">
                {{ $data.gst.address2 }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mb-3 col-lg-3 col-md-6">
              <label for="gstAgencyCity" class="label-require"> Agency City</label>
              <div class="form-text">
                {{ $data.gst.city }}
              </div>
            </div>
            <div class="mb-3 col-lg-3 col-md-6">
              <label for="gstCompositionLevy"> Composition Levy as per Section 10 of CGST </label>
              <div class="form-text" id="gstCompositionLevy" aria-label="gstCompositionLevy">
                <span value="1" v-if="$data.gst.composition_levy == 1">Yes</span>
                <span value="0" v-if="$data.gst.composition_levy == 0">No</span>
              </div>
            </div>
            <div class="mb-3 col-lg-3 col-md-6">
              <label for="gstProvisionalGSTNo">Provisional GST No</label>
              <div class="form-text">
                {{ $data.gst.provisional_gst_no }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import LoadingIcons from '../../common/LoadingIcons.vue';
export default {
  name: 'PartnerSignup',
  components: {
    // LoadingIcons,
  },
  data: function () {
    return {
      errors: {},
      ajaxLoad: {
        next: false,
      },
      countryList: [],
      gst: {},
      stateList: [],
      cityList: [],
    };
  },
  async created() {
    document.title = 'Trepr - Partner signup';
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        'content',
        'Trepr caters to both travelers and seekers who want travel companions/send packing service for seekers and to travelers who want to earn money during their travel'
      );
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        'content',
        'Trepr, trepr partner signup, trepr partner register, Travel companion, Package services, Flight companionship, Flight companion for elders, Package shipment, Shopping conceirge'
      );
    let token = this.$store.state.token;
    let self = this;
    // console.log("state $store ", this.$store.state)
    let apiUrl = process.env.VUE_APP_API_URL;
    let headerAuthentication = new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    //
    // var ajaxCityList = fetch(apiUrl + 'common/get-city', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
    //   },
    // }).then((response) => response.json());
    var ajaxDetail = fetch(apiUrl + 'partner/detail', {
      method: 'POST',
      headers: headerAuthentication,
      body: 'type=agent-gst',
    }).then((response) => response.json());
    await Promise.all([ajaxDetail])
      .then((resData) => {
        // console.log(resData);
        if (resData[0].status == 'error') {
          alert('error while geting data ' + resData[0].message);
          return;
        }
        // self.$data.cityList = resData[0].cityList;
        // if (resData[1].status == 'error') {
        //   alert('error while geting data ' + resData[1].message);
        //   return;
        // }
        self.$data.gst = resData[0].gst;
      })
      .catch((err) => {
        console.error('Error:', err);
      });
  },
};
</script>

<style scoped lang="scss">
@import '../../../assets/scss/new/partner/partner_signup.scss';
@import '../../../../node_modules/bs-stepper/dist/css/bs-stepper.min.css';
#bs-stepper-header .step {
  .btn-muted {
    background-color: #eee !important;
  }
  .step-trigger {
    padding: 10px;
  }
}
#bs-stepper-header .active .step-trigger {
  background-color: rgb(41, 115, 115) !important;
  span {
    color: #fff !important;
  }
}
</style>
