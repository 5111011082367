<template>
  <div
    :class="{
      'container-fluid px-lg-5 mb-3': true,
      'margin-top-0421': this.$route.query.ismobileapp == undefined,
    }"
  >
    <h1 class="text-primary text-center">Detail Profile information</h1>
    <div class="alert alert-success" role="alert" v-if="$data.actionType == 'updated'">
      Your information has been {{ $data.actionType }} successfully
    </div>
    <div class="row">
      <div class="col-sm-12">
        <DetailPersonal />
        <DetailAgency />
        <div>
          <DetailAgencyGst />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DetailPersonal from './DetailPersonal.vue';
import DetailAgency from './DetailAgency.vue';
import DetailAgencyGst from './DetailAgencyGst.vue';
import Cookies from 'js-cookie';
export default {
  name: 'Main',
  components: {
    DetailPersonal,
    DetailAgency,
    DetailAgencyGst,
  },
  data: function () {
    return {
      actionType: '',
      personalData: {},
      agencyData: {},
      agencyCountry: '',
      gstData: {},
      ajax: {},
      errorFields: {},
      stepper: {},
    };
  },
  async created() {
    document.title = 'Trepr - Profile detail | Flight Companion & Parcel Delivery Services';
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        'content',
        'Trepr (Trip Representative) is one of the peer to peer economy sharing trusted community marketplace for people to list, discover, and substitute another person for their travel needs around the world.'
      );
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        'content',
        'Trepr,Travel companion, Package services, Flight companionship, Flight companion for elders, Package shipment, Shopping conceirge'
      );

    this.$data.actionType = Cookies.get('actionType');
    Cookies.remove('actionType');
  },
};
</script>

<style scoped></style>
